<template>
    <div class="header-box">
        <div class="header">
            <div class="icon-box" style="margin-right: 10px">
                <i class="el-icon-s-fold" @click="chengleft(true)" v-if="!isCollapse"></i>
                <i class="el-icon-s-unfold" @click="chengleft(false)" v-else></i>
            </div>
            <!--<img src="../assets/1651469562(1).png" class="logo" >-->
            <el-tabs v-model="thispath" type="card" closable @edit="delpath" class="routecard" @tab-click="tabclick">
                <el-tab-pane :key="item.path" v-for="(item, index) in $store.state.routeArr" :label="item.name" :name="item.path"></el-tab-pane>
            </el-tabs>
            <!--<div class="icon-box" style="margin-left: auto;">
				<theme></theme>
			</div>
			<div class="icon-box">
				<i class="el-icon-full-screen" @click="screen"></i>
			</div>
			<div class="icon-box">
				<i class="el-icon-refresh-right" @click="$router.go(0)"></i>
			</div>
			<el-avatar :size="50" :src="shop.avatar" class="avatar"></el-avatar>
			<el-dropdown @command="handleCommand">
				<span class="el-dropdown-link">
					{{shop.name}}<i class="el-icon-arrow-down el-icon--right"></i>
				</span>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item command="1">店铺中心</el-dropdown-item>
					<el-dropdown-item command="3">提现</el-dropdown-item>
					<el-dropdown-item command='2'>退出登录</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>-->
        </div>
        <el-dialog title="提现" :visible.sync="tixianshow" width="350px">
            <el-form label-width="90px" label-position="left">
                <el-form-item label="余额: " prop="kddh">
                    {{ num }}
                </el-form-item>
                <el-form-item label="提现金额: " prop="kdmc">
                    <el-input-number v-model="money" size :min="0.01" :precision="2"></el-input-number>
                </el-form-item>
            </el-form>
            <div style="text-align: center">
                <el-button type="warning" @click="tixian">提现</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import theme from "@/components/theme.vue";
export default {
    data() {
        return {
            fullscreen: false,
            shop: JSON.parse(sessionStorage.getItem("shop")),
            password: "",
            num: 0,
            money: 1,
            tixianshow: false,
        };
    },
    created() {
        this.shop = JSON.parse(sessionStorage.getItem("shop"));
    },
    computed: {
        isCollapse() {
            return this.$store.state.leftnav.isCollapse;
        },
        routeArr() {
            return this.$store.state.routeArr;
        },
        thispath: {
            get() {
                return this.$store.state.thispath;
            },
            set(value) {},
        },
    },
    methods: {
        chengleft(val) {
            this.$store.commit("chengleft", val);
        },
        getImg(val) {
            this.admin.avatar = val;
        },
        screen() {
            let element = document.documentElement;
            if (this.fullscreen) {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitCancelFullScreen) {
                    document.webkitCancelFullScreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
            } else {
                if (element.requestFullscreen) {
                    element.requestFullscreen();
                } else if (element.webkitRequestFullScreen) {
                    element.webkitRequestFullScreen();
                } else if (element.mozRequestFullScreen) {
                    element.mozRequestFullScreen();
                } else if (element.msRequestFullscreen) {
                    // IE11
                    element.msRequestFullscreen();
                }
            }
            this.fullscreen = !this.fullscreen;
        },
        delpath(path) {
            if (path.indexOf("pinedit") > -1) {
                let id = path.split("?")[1].split("&")[0].split("=")[1];
                setTimeout(() => {
                    sessionStorage.removeItem("pinform" + id || "undefined");
                    sessionStorage.removeItem("pinwatchform" + id || "undefined");
                    sessionStorage.removeItem("pinteams" + id || "undefined");
                }, 200);
            }
            if (path.indexOf("qiangedit") > -1) {
                let id = path.split("?")[1].split("&")[0].split("=")[1];
                setTimeout(() => {
                    sessionStorage.removeItem("qiangform" + id || "undefined");
                    sessionStorage.removeItem("ddata" + id || "undefined");
                }, 200);
            }
            if (path.indexOf("editgoods") > -1) {
                let id = path.split("?")[1].split("&")[0].split("=")[1];
                setTimeout(() => {
                    sessionStorage.removeItem("goodsform" + id || "undefined");
                }, 200);
            }

            this.$store.commit("delpath", path);
        },
        tabclick(e) {
            this.$router.push(e.name);
        },

        async handleCommand(e) {
            if (e == 1) {
                this.$router.push("/shopcenter");
            } else if (e == 2) {
                sessionStorage.setItem("admin", "");
                this.$router.push("/");
            } else if (e == 3) {
                let res = await this.$api.balanceGet();
                this.num = res || 0;
                this.tixianshow = true;
            }
        },
        //修改添加管理员
        async changeadmin() {
            let res = await this.$api.changeadmin({
                account: this.admin.account,
                identity: this.admin.identity,
                id: this.admin.id,
                avatar: this.admin.avatar,
                nickname: this.admin.nickname,
                password: this.password,
            });
            if (res) {
                this.$notify.success("修改成功,请重新登录");
                sessionStorage.setItem("admin", "");
                this.$router.push("/");
            }
        },
        tixian() {
            if (this.num * 1 < this.money * 1) {
                this.$message.error({
                    showClose: true,
                    message: "提现金额不能大于余额",
                });
            } else {
                this.$confirm("是否确认提现" + this.money + "元?", "提示", {
                    confirmButtonText: "确认",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(async () => {
                        let res = await this.$api.balanceAdd({ money: this.money });
                        if (res) {
                            this.tixianshow = false;
                            this.money = 1;
                            this.$notify.success("操作成功");
                        }
                    })
                    .catch(() => {});
            }
        },
    },
    components: {
        theme,
    },
};
</script>
<style>
.header .el-tabs__header {
    margin-bottom: 0;
}
</style>
<style scoped>
.header {
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #fff;
}

.icon-box {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    font-size: 20px;
    justify-content: center;
}

.avatar {
    margin-right: 5px;
    margin-left: 10px;
    min-width: 50px;
}

.routecard {
    max-width: calc(100vw - 300px);
}
.page1 {
    min-width: 1200px;
    height: 100vh;
    background: #f5f5f5;
}
.logo-box {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    background: #000;
    padding: 0 20px;
    box-sizing: border-box;
    z-index: 999;
}
.logo {
    width: auto;
    height: 110px;
    margin-right: 10px;
    border-radius: 3px;
}
</style>
