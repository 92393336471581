<template>
    <!--<div class="inner">-->
    <router-view></router-view>
    <!--</div>-->
</template>

<script>
import { ICON_IMAGE, APP_NAME } from "./uilts/baseInfo";

let link = document.querySelector("link[rel*='icon']") || document.createElement("link");
link.type = "image/png";
link.rel = "shortcut icon";
link.href = require("@/assets/" + ICON_IMAGE);
document.getElementsByTagName("head")[0].appendChild(link);

// Add the APP_NAME to the metadata as the title
document.title = APP_NAME + "运营后台";

export default {
    name: "App",
    components: {},
};
</script>
<style>
@import "./assets/css/global.scss";
body,
html {
    height: 100%;
}
body {
    margin: 0;
    padding: 0;
}
.inner {
    overflow: auto;
}
.inner::-webkit-scrollbar {
    width: 0 !important;
}
.border {
    border: 1px solid red;
}
div {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
input {
    border: 0;
    background: none;
    outline: none; /*清除input点击之后的黑色边框*/
}
textarea {
    border: 0;
    resize: none;
    background: none;
    outline: none; /*清除input点击之后的黑色边框*/
}
.header_form .el-form-item {
    margin-bottom: 0;
}
.el-card {
    margin-bottom: 10px;
}
</style>
