import { getCurrentInstance } from "vue";

export const useStore = () => {
    const vm = getCurrentInstance();
    if (!vm) throw new Error("must be called in setup");
    return vm.proxy.$store;
};

export const useRouter = () => {
    const vm = getCurrentInstance();
    if (!vm) throw new Error("must be called in setup");
    return vm.proxy.$router;
};

export const useRoute = () => {
    const vm = getCurrentInstance();
    if (!vm) throw new Error("must be called in setup");
    return vm.proxy.$route;
};

export const usePermission = () => {
    const vm = getCurrentInstance();
    if (!vm) throw new Error("must be called in setup");
    return vm.proxy.$permission;
};
